<template>
  <base-section
    id="info"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
              v-on:click="goPage(card.url)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInfo',
    methods: {
      goPage: function (url) {
        console.log('goPage')
        const vm = this
        return vm.$router.push(url)
      },
    },
    data: () => ({
      cards: [
        {
          icon: 'mdi-puzzle-outline',
          title: '남강 살균 소독제',
          text: '최신의 정보기술 서비스 및 다양한 제품군을 공급함으로써 HACCP의 SOULUTION을 제공해 드립니다.',
          url: '/Disinfectant',
        },
        {
          icon: 'mdi-contrast-circle',
          title: '살균소독제 장단점',
          text: '기구·용기·포장용 살균 소독제 / 식품첨가물·혼합제제 ',
          url: '/Disinfectant',
        },
        {
          icon: 'mdi-human-queue',
          title: '급식단체 사용 기준',
          text: '급식단체 살균소독 및 세척사용 기준.',
          url: '/FoodServiceGroupGuid',
        },
        {
          icon: 'mdi-microwave',
          title: '급식단체용 세척제',
          text: '엑셀콩크(1종세제) / GP클리너(다목적 세척제) / 오븐클리너(강력 기름때 제거제) / 디스탄(은기물 변색 제거제) / 자동식기 세척기용 세제 / 드레인클리너(배수 파이프 세관제)',
          url: '/FoodServiceGroup',
        },

      ],
    }),
  }
</script>
